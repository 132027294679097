import { render, staticRenderFns } from "./CustomForm.vue?vue&type=template&id=54704d1c&"
import script from "./CustomForm.vue?vue&type=script&lang=ts&"
export * from "./CustomForm.vue?vue&type=script&lang=ts&"
import style0 from "./CustomForm.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VCol,VFileInput,VRadio,VRadioGroup,VTextField})
