export enum FieldTypes {
  MONEY = 0, // Деньги
  NUMBER = 1, // Число
  STRING = 2, // Строка
  DATE = 3, // Дата
  TIME = 4, // Время
  ENUM = 5, // Перечисляемое
  CONNECT = 6, // Поле связи
  CHECKBOX = 7, // Чекбокс
  PATTERN = 8, // Шаблон
  RANGE = 9, // Период
  TEXT = 10, // Текст
  LINK = 11, // Ссылка
  MANUAL = 12, // Пользовательский справочник
  MONTH = 13, // Месяц
  FILE = 14, // Файл
  COMBOBOX = 15, // Мультивыбор
}
